import { LinuxIcon, MacOSIcon, WindowsOSIcon } from "@/icons"
import { OSIconProps } from "./OSIcon.types"

export const OSIcon = ({ platform }: OSIconProps) => {
  switch (platform) {
    case "darwin_arm64":
    case "darwin_amd64":
      return <MacOSIcon />
    case "linux_arm64":
    case "linux_amd64":
      return <LinuxIcon />
    case "windows_amd64":
      return <WindowsOSIcon />
  }
}
