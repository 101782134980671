import { Typography } from "antd"

import styles from "./Text.module.scss"

import type { TextProps } from "antd/lib/typography/Text"

export const Text = ({ ellipsis, ...props }: TextProps) => {
  if (ellipsis)
    return (
      <span className={styles.ellipsis}>
        <Typography.Text {...props} />
      </span>
    )

  return <Typography.Text {...props} />
}
