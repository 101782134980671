import cn from "classnames"

import { Button as ButtonAtnd } from "antd"

import styles from "./Button.module.scss"

import type { ButtonProps } from "."

export const Button = (props: ButtonProps) => {
  const { type, size, className, dataTestId, ...restProps } = props
  const xsSize = size === "xs"
  const sizeProp = xsSize ? "small" : size
  const nextProps = {
    className: cn(className, {
      [styles.btnXs]: xsSize,
      [styles.size]: xsSize
    }),
    size: sizeProp,
    "data-testid": dataTestId,
    ...restProps
  }

  if (type === "ks") {
    const { className, ...rest } = nextProps

    return (
      <ButtonAtnd
        className={cn(styles.ksButton, "ant-btn-ks", className)}
        {...rest}
      />
    )
  }

  if (type === "secondary") {
    const { className, ...rest } = nextProps

    return <ButtonAtnd className={cn(styles.secondary, className)} {...rest} />
  }

  return <ButtonAtnd type={type} {...nextProps} />
}
