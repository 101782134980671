import cn from "classnames"

import { Tooltip as TooltipAntd, TooltipProps } from "antd"

export const Tooltip = ({
  rootClassName,
  overlayClassName,
  ...props
}: TooltipProps & { overlayClassName?: string }) => (
  <TooltipAntd
    rootClassName={cn(rootClassName, "root-class-tooltip-antd-ks")}
    classNames={overlayClassName ? { root: overlayClassName } : undefined}
    {...props}
  />
)
