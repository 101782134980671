import { useCallback, useEffect, useRef, useState } from "react"

import { DrawerComponent } from "./DrawerComponent"
import { HandleResizableDrawer } from "./HandleResizableDrawer"

import type { ResizableDrawerComponent } from "."

export const getLayoutWidth = () => {
  const OFFSET_LEFT = 50
  const clientWidth = document.getElementById(
    "outlet-layout-container"
  )?.clientWidth

  return (clientWidth || OFFSET_LEFT) - OFFSET_LEFT
}

export const ResizableDrawer: ResizableDrawerComponent = (props) => {
  const { width, minWidth, maxWidth, ...drawerProps } = props
  const [isResizing, setIsResizing] = useState(false)
  const [drawerWidth, setDrawerWidth] = useState(width)
  const layoutWidth = useRef(getLayoutWidth())
  const mouseMoveEvent = "mousemove" as const
  const mouseUpEvent = "mouseup" as const

  const handleMouseMove = (event: MouseEvent) => {
    const { clientX } = event
    const { offsetWidth, offsetLeft } = document.body
    const widthValue = offsetWidth - (clientX - offsetLeft)
    const minWidthValue = minWidth || width
    const DEFAULT_MAX_WIDTH = 1500
    const maxWidthValue = maxWidth || layoutWidth.current || DEFAULT_MAX_WIDTH

    if (widthValue > minWidthValue && widthValue < maxWidthValue) {
      setDrawerWidth(widthValue)
    }
  }

  const handleMouseUp = () => {
    document.removeEventListener(mouseMoveEvent, cbMouseMove)
    document.removeEventListener(mouseUpEvent, cbMouseUp)
    setIsResizing(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cbMouseMove = useCallback(handleMouseMove, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cbMouseUp = useCallback(handleMouseUp, [])

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    document.addEventListener(mouseMoveEvent, cbMouseMove)
    document.addEventListener(mouseUpEvent, cbMouseUp)
    layoutWidth.current = getLayoutWidth()

    setIsResizing(true)
  }

  useEffect(() => {
    // case when several drawers are open and then the drawers on the background move to forward
    if (drawerWidth !== width && drawerWidth < width) setDrawerWidth(width)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  const { resizable, children, afterOpenChange, ...restProps } = drawerProps

  return (
    <DrawerComponent
      resizableView={isResizing}
      width={drawerWidth}
      afterOpenChange={(open) => {
        if (!open) setDrawerWidth(width)
        afterOpenChange?.(open)
      }}
      {...restProps}
    >
      <HandleResizableDrawer onMouseDown={handleMouseDown} />
      {children}
    </DrawerComponent>
  )
}
