import cn from "classnames"
import { ForwardedRef, forwardRef } from "react"

import { Input as InputAntd, type InputRef } from "antd"

import styles from "./Input.module.scss"

import type { InputProps } from "."

export const Input = forwardRef(function InputComponent(
  {
    placeholderAlign,
    placeholderTextTransform,
    className,
    testId,
    ...props
  }: InputProps,
  ref: ForwardedRef<InputRef>
) {
  const currentClassName = cn(className, {
    [styles.placeholderAlignCenter]: placeholderAlign === "center",
    [styles.placeholderCapitalizeTextTransform]:
      placeholderTextTransform === "capitalize"
  })

  return (
    <InputAntd
      ref={ref}
      className={currentClassName}
      data-testid={testId}
      {...props}
    />
  )
})
