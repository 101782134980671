import styles from "./CellTable.module.scss"

import type { Entity, Value, WithTooltip } from "."

export const CellTable = (value: Value | WithTooltip, record?: Entity) => {
  const useValue = typeof value !== "object"
  const text = useValue ? value : value.text
  const tooltip = useValue ? value : value.tooltip

  return (
    <span
      className={styles.wrap}
      data-tooltip-content={record?.noTooltip ? undefined : tooltip}
    >
      {text}
    </span>
  )
}
